import {configure } from "vee-validate";
import * as Yup from 'yup';
import { pt } from 'yup-locale-pt';

export default defineNuxtPlugin((nuxtApp) => {
  configure({
    validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
    validateOnChange: true, // controls if `change` events should trigger validation with `handleChange` handler
    validateOnInput: false, // controls if `input` events should trigger validation with `handleChange` handler
    validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
  });

  Yup.setLocale(pt);


});

