// plugins/utils/helpers.js

export default defineNuxtPlugin(() => {
  class Utils {
    formatCurrency(value: Number) {
      // Implemente a lógica para formatar o valor monetário aqui
      return `R$ ${value.toFixed(2)}`;
    }
  }

  return { provide: { utils: new Utils() } }
})



