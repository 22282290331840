import revive_payload_client_4sVQNw7RlN from "/root/jupeixotodoceria-web/jupeixotodoceria-site/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/root/jupeixotodoceria-web/jupeixotodoceria-site/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/root/jupeixotodoceria-web/jupeixotodoceria-site/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/root/jupeixotodoceria-web/jupeixotodoceria-site/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/root/jupeixotodoceria-web/jupeixotodoceria-site/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/root/jupeixotodoceria-web/jupeixotodoceria-site/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/root/jupeixotodoceria-web/jupeixotodoceria-site/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/root/jupeixotodoceria-web/jupeixotodoceria-site/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/root/jupeixotodoceria-web/jupeixotodoceria-site/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/root/jupeixotodoceria-web/jupeixotodoceria-site/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/root/jupeixotodoceria-web/jupeixotodoceria-site/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LcKgStRyi6 from "/root/jupeixotodoceria-web/jupeixotodoceria-site/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import maska_UHaKf2z1iQ from "/root/jupeixotodoceria-web/jupeixotodoceria-site/plugins/maska.ts";
import modals_RaVbrZQ2Fe from "/root/jupeixotodoceria-web/jupeixotodoceria-site/plugins/modals.ts";
import utils_EEhlE5KdlH from "/root/jupeixotodoceria-web/jupeixotodoceria-site/plugins/utils.ts";
import v_money_spinner_epWnAbm7ze from "/root/jupeixotodoceria-web/jupeixotodoceria-site/plugins/v-money-spinner.ts";
import veevalidate_components_HZdjzYOWQC from "/root/jupeixotodoceria-web/jupeixotodoceria-site/plugins/veevalidate-components.ts";
import veevalidate_rules_Xc2IpxFdQq from "/root/jupeixotodoceria-web/jupeixotodoceria-site/plugins/veevalidate-rules.ts";
import websocket_client_IwtQxfNJbn from "/root/jupeixotodoceria-web/jupeixotodoceria-site/plugins/websocket.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_LcKgStRyi6,
  maska_UHaKf2z1iQ,
  modals_RaVbrZQ2Fe,
  utils_EEhlE5KdlH,
  v_money_spinner_epWnAbm7ze,
  veevalidate_components_HZdjzYOWQC,
  veevalidate_rules_Xc2IpxFdQq,
  websocket_client_IwtQxfNJbn
]