
import * as ipxRuntime$5ygQnqH0lq from '/root/jupeixotodoceria-web/jupeixotodoceria-site/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "default": {
      "modifiers": {
        "format": "webp",
        "loading": "lazy",
        "quality": "80"
      }
    }
  },
  "provider": "ipx",
  "domains": [
    "api.jupeixotodoceria.com"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$5ygQnqH0lq, defaults: {"name":"ipx","provider":"/root/jupeixotodoceria-web/jupeixotodoceria-site/node_modules/@nuxt/image/dist/runtime/providers/ipx"} }
}
        