import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "128",
  height: "128",
  version: "1.0",
  viewBox: "0 0 96 96"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<defs><clipPath id=\"a\"><path d=\"M15 27.242h1.313v1.313H15Zm0 0\"></path></clipPath><clipPath id=\"b\"><path d=\"M16.2 27.71c-.47.75-.919.622-1.012.454-.094-.172-.02-.621.132-.734.149-.11.73.058.73.058\"></path></clipPath></defs><path fill=\"#e21b1b\" d=\"M96 48q-.001 2.357-.23 4.703a48.239 48.239 0 0 1-3.422 13.664 48 48 0 0 1-2.016 4.262 48.028 48.028 0 0 1-23.965 21.719 48 48 0 0 1-9.004 2.73q-2.309.457-4.66.692A49 49 0 0 1 48 96q-2.357-.001-4.703-.23a48.239 48.239 0 0 1-13.664-3.422 48 48 0 0 1-4.262-2.016A48.028 48.028 0 0 1 3.652 66.367a48 48 0 0 1-2.73-9.004 49 49 0 0 1-.692-4.66A49 49 0 0 1 0 48q.001-2.357.23-4.703.235-2.351.692-4.66a48.2 48.2 0 0 1 2.73-9.004 48 48 0 0 1 2.016-4.262A48.028 48.028 0 0 1 29.633 3.652a48 48 0 0 1 9.004-2.73 49 49 0 0 1 4.66-.692A49 49 0 0 1 48 0q2.357.001 4.703.23 2.351.235 4.66.692a48.2 48.2 0 0 1 9.004 2.73 48 48 0 0 1 4.262 2.016 48.028 48.028 0 0 1 21.719 23.965 48 48 0 0 1 2.73 9.004q.457 2.309.692 4.66A49 49 0 0 1 96 48m0 0\"></path><path fill=\"#e21b1b\" d=\"M95.676 53.496 68.984 26.801 28.172 69.113 54.594 95.54c21.597-2.973 38.605-20.297 41.082-42.043m0 0\"></path><path fill=\"#fff\" d=\"M66.445 69.664q-1.552 0-2.652-1.098l-36.34-36.343a3.744 3.744 0 0 1 0-5.305 3.746 3.746 0 0 1 5.3 0l36.345 36.344a3.75 3.75 0 0 1 0 5.304 3.62 3.62 0 0 1-2.653 1.098m0 0\"></path><path fill=\"#fff\" d=\"M30.102 69.664q-1.552 0-2.653-1.098a3.75 3.75 0 0 1 0-5.304l36.344-36.344a3.751 3.751 0 0 1 5.305 5.305L32.754 68.566a3.62 3.62 0 0 1-2.652 1.098m0 0\"></path><g clip-path=\"url(#a)\"><g clip-path=\"url(#b)\"><path fill=\"#e7191f\" d=\"M14.438 26.68h2.437v2.437h-2.437Zm0 0\"></path></g></g>", 6)
  ])))
}
export default { render: render }