import { defineStore } from 'pinia';
import type { App } from 'vue';

interface SocketStore {
  isConnected: boolean;
  message: string;
  reconnectError: boolean;
  heartBeatInterval: number;
  heartBeatTimer: number;
}

let socketStoreInstance: ReturnType<typeof useSocketStore> | null = null;

export const useSocketStore = (app: App<Element>) => {
  return defineStore({
    id: 'socket',
    state: (): SocketStore => ({
      isConnected: false,
      message: '',
      reconnectError: false,
      heartBeatInterval: 50000,
      heartBeatTimer: 0,
    }),
    actions: {
      SOCKET_ONOPEN(event: any) {
        console.info('Websocket conectado com sucesso!');
        app.config.globalProperties.$socket = event.currentTarget;
        this.isConnected = true;
        this.heartBeatTimer = window.setInterval(() => {
          const message = 'Mensagem de batimento cardíaco - NuxtJS';
          this.isConnected &&
            app.config.globalProperties.$socket.send({
              code: 200,
              msg: message,
            });
        }, this.heartBeatInterval);
      },
      SOCKET_ONCLOSE(event: any) {
        this.isConnected = false;
        window.clearInterval(this.heartBeatTimer);
        this.heartBeatTimer = 0;
      },
      SOCKET_ONERROR(event: any) {
        console.error(event);
      },
      SOCKET_ONMESSAGE(message: any) {
        try {
          this.message = JSON.parse(message);
        } catch (error) {
          console.error('Erro ao parsear a mensagem:', error);
        }
      },
      SOCKET_RECONNECT(count: any) {
        console.info('O sistema de mensagens está se reconectando NUXTJS...', count);
      },
      SOCKET_RECONNECT_ERROR() {
        this.reconnectError = true;
      },
    },
  })();
};

// Need to be used outside the setup
export function useSocketStoreWithOut(app: App<Element>) {
  if (!socketStoreInstance) {
    socketStoreInstance = useSocketStore(app);
  }
  return socketStoreInstance;
}
