<template>
  <header class="bg-white">
    <nav class="container p-4 flex">
      <NuxtLink to="/" class="mx-auto pt-4 sm:mx-0 sm:pt-0">
        <nuxt-img src="/img/logo.png" class="h-16 sm:h-16 mx-auto" alt="Jú Peixoto Doceria" />
      </NuxtLink>
    </nav>
  </header>
  <div class="mt-7 mx-auto text-center card p-4">
    <p class="mt-7 text-3xl sm:text-4xl">Oooops! Deixou seu brownie cair?</p>
    <nuxt-img src="/img/brownie-404.png" class="mx-auto" alt="Brownie" width="450"/>
    <p class="font-bold text-lg">Erro {{ error.statusCode }}</p>
    <p class="font-bold">{{ error.message }}</p>
    <button
      class="w-full text-center text-white font-bold sm:w-2/6 px-4 py-4 rounded outline-none focus:outline-none
            bg-pink-jpd-400 active:bg-pink-jpd-400 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all mt-5"
      @click="voltarPaginaPrincipal">
      Página Principal
    </button>

  </div>
</template>

<script setup>

defineProps(["error"]);

const voltarPaginaPrincipal = () => {
  clearError({ redirect: "/" });
};
</script>

<style scoped></style>
