import { defineNuxtPlugin } from '#app';
import { useSocketStoreWithOut } from '~~/stores/socketStore';
import WebSocketService from '~~/services/websocketService';

export default defineNuxtPlugin((nuxtApp) => {
  const store = useSocketStoreWithOut(nuxtApp.vueApp);

  const webSocketService = WebSocketService.getInstance(store);

  nuxtApp.provide('webSocketService', webSocketService);

  nuxtApp.provide('connectWebSocket', (url: string, token: string, identifier: string) => {
    webSocketService.connect(url, token, identifier);
  });

  nuxtApp.provide('authenticateWebSocket', (authToken: string, identifier: string) => {
    webSocketService.authenticate(authToken, identifier);
  });

  nuxtApp.provide('onWebSocketConnected', (callback: () => void) => {
    webSocketService.onConnected(callback);
  });

  nuxtApp.provide('startConnectionMonitor', () => {
    webSocketService.startConnectionMonitor();
  });

});
