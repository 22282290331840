import { default as index3rDSolDOlZMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/categories/index.vue?macro=true";
import { default as indexisU9aMgn0bMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/configurations/index.vue?macro=true";
import { default as index0cfPHlDsw1Meta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/help/index.vue?macro=true";
import { default as indexhULiILPdVBMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/index.vue?macro=true";
import { default as indexL4sdNiuCFMMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/login/index.vue?macro=true";
import { default as _91id_93GePSDU7RAVMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/categories/[id].vue?macro=true";
import { default as add7vmVXynLINMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/categories/add.vue?macro=true";
import { default as indexf2OgIqvSeCMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/categories/index.vue?macro=true";
import { default as _91id_93jyzr7tbTEtMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/complements/[id].vue?macro=true";
import { default as addbpztOpbZjxMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/complements/add.vue?macro=true";
import { default as indexepx3fuU33MMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/complements/index.vue?macro=true";
import { default as indexlxm3CIfVK3Meta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/configurations/index.vue?macro=true";
import { default as indexeuPfATLFuGMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/dashboard/index.vue?macro=true";
import { default as _91id_93CXSZn3sqPcMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/delivery-time/[id].vue?macro=true";
import { default as addA2ExVJ4gl9Meta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/delivery-time/add.vue?macro=true";
import { default as index9N3cmgFsoOMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/delivery-time/index.vue?macro=true";
import { default as _91id_934ATra3c1ZkMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/delivery-type/[id].vue?macro=true";
import { default as addODd2vca3blMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/delivery-type/add.vue?macro=true";
import { default as index8wGv1DsPL2Meta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/delivery-type/index.vue?macro=true";
import { default as _91id_93tXzs1kXHwWMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/flavors/[id].vue?macro=true";
import { default as add66zU0I2ZMZMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/flavors/add.vue?macro=true";
import { default as indexn2OfvDkxzpMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/flavors/index.vue?macro=true";
import { default as indexnrqwK4QUMxMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/help/index.vue?macro=true";
import { default as _91id_93kvVdxUQb35Meta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/order-status/[id].vue?macro=true";
import { default as addgvkkbB5CQpMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/order-status/add.vue?macro=true";
import { default as indexl6PCEHNHunMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/order-status/index.vue?macro=true";
import { default as _91id_93jDEGUkLm6qMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/orders/[id].vue?macro=true";
import { default as indexBjgnzRTVU7Meta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/orders/index.vue?macro=true";
import { default as _91id_93ZwBeCnhMRuMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/payment-method/[id].vue?macro=true";
import { default as addFp4o93RY8lMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/payment-method/add.vue?macro=true";
import { default as indexgdGJoXYipNMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/payment-method/index.vue?macro=true";
import { default as _91id_93fnJnYNdjXkMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/products/[id].vue?macro=true";
import { default as adddMIbcuCDLLMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/products/add.vue?macro=true";
import { default as index4maiaiSUkLMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/products/index.vue?macro=true";
import { default as indexraF0DYgSriMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/push-notification/index.vue?macro=true";
import { default as _91id_93Nx2H8HOY9cMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/sizes/[id].vue?macro=true";
import { default as addWScjm5aMp7Meta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/sizes/add.vue?macro=true";
import { default as indexiyLziol04rMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/sizes/index.vue?macro=true";
import { default as indexItph8Vcbx3Meta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/users/index.vue?macro=true";
import { default as indexq6WCrf1b7OMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/orders/index.vue?macro=true";
import { default as indexv9U0wYViZZMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/products/index.vue?macro=true";
import { default as indexsHx8ijxx4RMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/auth/recoverypassword/index.vue?macro=true";
import { default as indexUpZUVm2o6RMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/index.vue?macro=true";
import { default as indexrjcTThUc7lMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/privacy-policy/index.vue?macro=true";
import { default as _91slug_93DOu33httNSMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/products/[slug].vue?macro=true";
import { default as indexGD3ZSWw6FGMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/products/index.vue?macro=true";
import { default as indexnTzFr5CymbMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/terms-and-conditions-of-use/index.vue?macro=true";
import { default as _91id_93aRVrUOVbsUMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/user/changepassword/[id].vue?macro=true";
import { default as indexZVSFOW3eM0Meta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/user/login/index.vue?macro=true";
import { default as index6mNjn30pUyMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/user/management/index.vue?macro=true";
import { default as index4yvKu7ZX2SMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/user/management/order/index.vue?macro=true";
import { default as indexdaln2fggbnMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/user/management/profile/address/index.vue?macro=true";
import { default as indexJ9jOrR8cfoMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/user/management/profile/configuration/change-password/index.vue?macro=true";
import { default as indexswUfBkzDrwMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/user/management/profile/configuration/delete-account/index.vue?macro=true";
import { default as index9pNCkurm1PMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/user/management/profile/configuration/index.vue?macro=true";
import { default as indexGEjC4OFhigMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/user/management/profile/index.vue?macro=true";
import { default as indexSw3oFAH6l5Meta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/user/management/profile/personal-information/index.vue?macro=true";
import { default as index716CvmVaIBMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/user/management/profile/security/change-password/index.vue?macro=true";
import { default as indexHvkwkHiOMEMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/user/management/profile/security/index.vue?macro=true";
import { default as indexapgBgHuNHrMeta } from "/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/user/register/index.vue?macro=true";
export default [
  {
    name: "admin-categories",
    path: "/admin/categories",
    meta: index3rDSolDOlZMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-configurations",
    path: "/admin/configurations",
    meta: indexisU9aMgn0bMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/configurations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-help",
    path: "/admin/help",
    meta: index0cfPHlDsw1Meta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/help/index.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexhULiILPdVBMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-login",
    path: "/admin/login",
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/login/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-management-categories-id",
    path: "/admin/management/categories/:id()",
    meta: _91id_93GePSDU7RAVMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/categories/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-management-categories-add",
    path: "/admin/management/categories/add",
    meta: add7vmVXynLINMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/categories/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-management-categories",
    path: "/admin/management/categories",
    meta: indexf2OgIqvSeCMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-management-complements-id",
    path: "/admin/management/complements/:id()",
    meta: _91id_93jyzr7tbTEtMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/complements/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-management-complements-add",
    path: "/admin/management/complements/add",
    meta: addbpztOpbZjxMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/complements/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-management-complements",
    path: "/admin/management/complements",
    meta: indexepx3fuU33MMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/complements/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-management-configurations",
    path: "/admin/management/configurations",
    meta: indexlxm3CIfVK3Meta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/configurations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-management-dashboard",
    path: "/admin/management/dashboard",
    meta: indexeuPfATLFuGMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-management-delivery-time-id",
    path: "/admin/management/delivery-time/:id()",
    meta: _91id_93CXSZn3sqPcMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/delivery-time/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-management-delivery-time-add",
    path: "/admin/management/delivery-time/add",
    meta: addA2ExVJ4gl9Meta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/delivery-time/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-management-delivery-time",
    path: "/admin/management/delivery-time",
    meta: index9N3cmgFsoOMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/delivery-time/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-management-delivery-type-id",
    path: "/admin/management/delivery-type/:id()",
    meta: _91id_934ATra3c1ZkMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/delivery-type/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-management-delivery-type-add",
    path: "/admin/management/delivery-type/add",
    meta: addODd2vca3blMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/delivery-type/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-management-delivery-type",
    path: "/admin/management/delivery-type",
    meta: index8wGv1DsPL2Meta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/delivery-type/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-management-flavors-id",
    path: "/admin/management/flavors/:id()",
    meta: _91id_93tXzs1kXHwWMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/flavors/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-management-flavors-add",
    path: "/admin/management/flavors/add",
    meta: add66zU0I2ZMZMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/flavors/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-management-flavors",
    path: "/admin/management/flavors",
    meta: indexn2OfvDkxzpMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/flavors/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-management-help",
    path: "/admin/management/help",
    meta: indexnrqwK4QUMxMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/help/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-management-order-status-id",
    path: "/admin/management/order-status/:id()",
    meta: _91id_93kvVdxUQb35Meta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/order-status/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-management-order-status-add",
    path: "/admin/management/order-status/add",
    meta: addgvkkbB5CQpMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/order-status/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-management-order-status",
    path: "/admin/management/order-status",
    meta: indexl6PCEHNHunMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/order-status/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-management-orders-id",
    path: "/admin/management/orders/:id()",
    meta: _91id_93jDEGUkLm6qMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-management-orders",
    path: "/admin/management/orders",
    meta: indexBjgnzRTVU7Meta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-management-payment-method-id",
    path: "/admin/management/payment-method/:id()",
    meta: _91id_93ZwBeCnhMRuMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/payment-method/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-management-payment-method-add",
    path: "/admin/management/payment-method/add",
    meta: addFp4o93RY8lMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/payment-method/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-management-payment-method",
    path: "/admin/management/payment-method",
    meta: indexgdGJoXYipNMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/payment-method/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-management-products-id",
    path: "/admin/management/products/:id()",
    meta: _91id_93fnJnYNdjXkMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/products/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-management-products-add",
    path: "/admin/management/products/add",
    meta: adddMIbcuCDLLMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/products/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-management-products",
    path: "/admin/management/products",
    meta: index4maiaiSUkLMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/products/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-management-push-notification",
    path: "/admin/management/push-notification",
    meta: indexraF0DYgSriMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/push-notification/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-management-sizes-id",
    path: "/admin/management/sizes/:id()",
    meta: _91id_93Nx2H8HOY9cMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/sizes/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-management-sizes-add",
    path: "/admin/management/sizes/add",
    meta: addWScjm5aMp7Meta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/sizes/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-management-sizes",
    path: "/admin/management/sizes",
    meta: indexiyLziol04rMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/sizes/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-management-users",
    path: "/admin/management/users",
    meta: indexItph8Vcbx3Meta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/management/users/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-orders",
    path: "/admin/orders",
    meta: indexq6WCrf1b7OMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-products",
    path: "/admin/products",
    meta: indexv9U0wYViZZMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/admin/products/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-recoverypassword",
    path: "/auth/recoverypassword",
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/auth/recoverypassword/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "products-slug",
    path: "/products/:slug()",
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions-of-use",
    path: "/terms-and-conditions-of-use",
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/terms-and-conditions-of-use/index.vue").then(m => m.default || m)
  },
  {
    name: "user-changepassword-id",
    path: "/user/changepassword/:id()",
    meta: _91id_93aRVrUOVbsUMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/user/changepassword/[id].vue").then(m => m.default || m)
  },
  {
    name: "user-login",
    path: "/user/login",
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/user/login/index.vue").then(m => m.default || m)
  },
  {
    name: "user-management",
    path: "/user/management",
    meta: index6mNjn30pUyMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/user/management/index.vue").then(m => m.default || m)
  },
  {
    name: "user-management-order",
    path: "/user/management/order",
    meta: index4yvKu7ZX2SMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/user/management/order/index.vue").then(m => m.default || m)
  },
  {
    name: "user-management-profile-address",
    path: "/user/management/profile/address",
    meta: indexdaln2fggbnMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/user/management/profile/address/index.vue").then(m => m.default || m)
  },
  {
    name: "user-management-profile-configuration-change-password",
    path: "/user/management/profile/configuration/change-password",
    meta: indexJ9jOrR8cfoMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/user/management/profile/configuration/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "user-management-profile-configuration-delete-account",
    path: "/user/management/profile/configuration/delete-account",
    meta: indexswUfBkzDrwMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/user/management/profile/configuration/delete-account/index.vue").then(m => m.default || m)
  },
  {
    name: "user-management-profile-configuration",
    path: "/user/management/profile/configuration",
    meta: index9pNCkurm1PMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/user/management/profile/configuration/index.vue").then(m => m.default || m)
  },
  {
    name: "user-management-profile",
    path: "/user/management/profile",
    meta: indexGEjC4OFhigMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/user/management/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "user-management-profile-personal-information",
    path: "/user/management/profile/personal-information",
    meta: indexSw3oFAH6l5Meta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/user/management/profile/personal-information/index.vue").then(m => m.default || m)
  },
  {
    name: "user-management-profile-security-change-password",
    path: "/user/management/profile/security/change-password",
    meta: index716CvmVaIBMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/user/management/profile/security/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "user-management-profile-security",
    path: "/user/management/profile/security",
    meta: indexHvkwkHiOMEMeta || {},
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/user/management/profile/security/index.vue").then(m => m.default || m)
  },
  {
    name: "user-register",
    path: "/user/register",
    component: () => import("/root/jupeixotodoceria-web/jupeixotodoceria-site/pages/user/register/index.vue").then(m => m.default || m)
  }
]