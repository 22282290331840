export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, shrink-to-fit=no"},{"key":"description","name":"description","content":"Descubra na Jú Peixoto Doceria uma variedade de doces artesanais deliciosos desde 2018. Especializada em bolos de festa, bolo vulcão, brigadeiros, mini brownies, e sobremesas criativas para todas as ocasiões, incluindo lembranças de Natal e Dia dos Namorados. Nossa missão é superar suas expectativas com cada doce!"},{"key":"keywords","name":"keywords","content":"Doceria em Cabo Frio, Confeitaria Artesanal Cabo Frio, Bolo de Aniversário Personalizado, Bolo de Pote, Doces Finos e Tradicionais, Brigadeiros, Brownie Artesanal, Bolo Vulcão, Sobremesas, Pavê e Pudim Caseiros, Lembranças para Eventos (Natal, Dia dos Namorados, Páscoa), Produtos com Nutella e Leite Ninho, ovos de chocolate, ovos de páscoa."}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"pt-BR"},"title":"Jú Peixoto Doceria - Doces Artesanais e Bolos Personalizados"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false