class WebSocketService {
  private static instance: WebSocketService | null = null;
  private socket: WebSocket | null = null;
  private store: any;
  private url: string = '';
  private publicToken: string = '';
  private identifier: string = '';
  private reconnectInterval: number = 10000; // Intervalo de reconexão em milissegundos
  private connectionMonitorInterval: number = 60000;
  private onConnectedCallback: (() => void) | null = null; // Callback para conexão aberta
  private broadcastChannel: BroadcastChannel;
  private activeTabsCount: number = 0;

  private constructor(store: any) {
    this.store = store;
    this.broadcastChannel = new BroadcastChannel('websocket_channel');

    // Listen to messages from other tabs
    this.broadcastChannel.onmessage = (event) => {
      if (event.data === 'connect') {
        this.activeTabsCount += 1;
        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
          this.connect(this.url, this.publicToken, this.identifier);
        }
      } else if (event.data === 'disconnect') {
        this.activeTabsCount -= 1;
        if (this.activeTabsCount <= 0) {
          this.close();
        }
      }
    }

    window.addEventListener('beforeunload', () => {
      this.broadcastChannel.postMessage('disconnect');
    });

    window.addEventListener('online', () => {
      if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
        this.connect(this.url, this.publicToken, this.identifier);
      }
    });
  }

  static getInstance(store: any) {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService(store);
    }
    return WebSocketService.instance;
  }

  connect(url: string, publicToken: string, identifier: string) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      return;
    }

    this.url = url;
    this.publicToken = publicToken;
    this.identifier = identifier;
    const wsURL: string = `${url}?token=${publicToken}&identifier=${identifier}`;

    this.socket = new WebSocket(wsURL);

    this.socket.onopen = (event) => {
      this.store.SOCKET_ONOPEN(event);

      if (this.onConnectedCallback) {
        this.onConnectedCallback();
      }
      // Notify other tabs that a connection is established
      this.broadcastChannel.postMessage('connect');
    };

    this.socket.onclose = (event) => {
      this.store.SOCKET_ONCLOSE(event);
      // this.reconnect();
    };

    this.socket.onerror = (event) => {
      this.store.SOCKET_ONERROR(event);
    };

    this.socket.onmessage = (event) => {
      this.store.SOCKET_ONMESSAGE(event.data);
    };
  }

  onConnected(callback: () => void) {
    this.onConnectedCallback = callback;
  }

  authenticate(authToken: string, identifier: string) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify({ type: 'authenticate', token: authToken, identifier: identifier }));
    } else {
      console.error('WebSocket is not connected.');
    }
  }

  send(message: object) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(message));
    } else {
      console.error('WebSocket is not connected.');
    }
  }

  close() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
      // Notify other tabs that the connection is closed
      this.broadcastChannel.postMessage('disconnect');
    }
  }

  reconnect() {
    setTimeout(() => {
      if (this.socket?.readyState !== WebSocket.OPEN) {
        this.connect(this.url, this.publicToken, this.identifier);
      }
    }, this.reconnectInterval);
  }

  startConnectionMonitor() {
    setInterval(() => {
      if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
        this.connect(this.url, this.publicToken, this.identifier);
      }
    }, this.connectionMonitorInterval);
  }
}

export default WebSocketService;
